.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  font-size: calc(10px + 1vmin);
  color: black;
}


.item {
  margin-top: 10px;
  margin-bottom: 2px;
  font-family: 'Avenir Next';
  font-weight: 500;
  text-align: left;
  font-size: .8rem;
  --bs-gutter-x: 0;
}

.row-item {
  margin-left: 10px;
  padding-right: 0;
}

.custom-row {
  width: 66.66667%;
}

.heading {
  margin-top: 5vh;
  font-size: 1.5rem !important;
  align-self: flex-start;
  font-family: 'Zilla-Slab' !important;
}


.custom-nav {
  height: 8vh;
  padding-left: 2vh;
  border-color: black;
  border-bottom-width: 5px;
  padding-top: 4px;
}

.button {
  margin-top: 24px;
  
  background-color: #000000 !important;
}

.divider {
  border-top: 1px solid black;
  width: 200rem;
  margin-top: 0.2rem;
  margin-bottom: 2rem;
}

.datepicker .react-datepicker-wrapper{
  display: inherit;
}



@media (max-width: 767px) {
  /* Styles for mobile devices */
  /* Add your mobile-specific styles here */
  .item {
    margin-left: 5vw !important;
  }

  .custom-row {
    width: 100%;
  }

  .col-md-8 {
    width: 80%;
    margin-left: 0px !important;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

}


/* CSS for the Card component */
.custom-card {
  width: 35vw;
  display: flex;
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 24px;
}

.checkmark {
  flex: 0 0 auto;
  margin-right: 3vw;
  margin-left: 6vw;
}

.card-content {
  
  flex: 1;
  margin-right: 5vw;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 4px;
  color: black;
}

.info {
  font-size: 14px;
  color: #555;
}